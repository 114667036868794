const constants = {
  trialDays: 7,
  exerciseLimits: {
    basic: 25,
    premium: 300,
    admin: 1000
  },
  prices: {
    standard: '$11.99',
    discounted: '$6.99',
    discount: 40,
    launchOfferCode: 'LAUNCH40'
  }
};

export default constants;