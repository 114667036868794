import React, { useState, useRef, useEffect, useContext } from "react";
import { AuthContext } from '../context/AuthContext';
import ErrorMessage from "./ErrorMessage";
import FeedbackSpeaking from "./FeedbackSpeaking";
import AudioRecorder from "./AudioRecorder";
import Instructions from "./Instructions";
import Prompt from "./Prompt";
import Image from "./Image";
import ExerciseTitle from "./ExerciseTitle";
import ProgressBar from "./ProgressBar";
import useStreamExercise from "../hooks/useStreamExercise";
import useStreamFeedback from "../hooks/useStreamFeedback";
import ExerciseNavigation from "./ExerciseNavigation";
import { useNavigate } from 'react-router-dom';
import CorrectedResponse from "./CorrectedResponse";
import LikeExercise from './LikeExercise';
import TimeCount from "./TimeCount";
import Images from "./Images";

const SpeakingExercise = ({ api, onBack: customOnBack, onNext: customOnNext, disableBack }) => {
  const { user, refreshUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { exercise, exerciseId, error: exerciseError, refetchExercise } = useStreamExercise(api);
  const { feedback, setFeedback, error: feedbackError, submitExercise, isComplete: isFeedbackComplete } = useStreamFeedback(api);
  const feedbackWasNull = useRef(true);
  const [likeReset, setLikeReset] = useState(0);
  const [recordingTime, setRecordingTime] = useState(0);

  useEffect(() => {
    if (feedback !== null && feedbackWasNull.current) refreshUser();
    feedbackWasNull.current = feedback === null;
  }, [feedback, refreshUser]);

  const handleStopRecording = (blob) => {
    const formData = new FormData();
    formData.append("exerciseId", exerciseId);
    formData.append("audio", blob, `audio.${blob.type.split("/")[1]}`);
    submitExercise(formData);
  };

  const handleRefresh = () => {
    setFeedback(null);
    refetchExercise();
    setLikeReset(prev => prev + 1);
  };

  const handleBack = () => customOnBack?.() || navigate('/exercises');
  const handleNext = () => customOnNext?.() || handleRefresh();

  const handleTimeUpdate = (time) => {
    setRecordingTime(time);
  };

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <ExerciseTitle api={api} />
      <div style={{ background: '#fff', borderRadius: '12px', boxShadow: '0 0 10px rgba(0,0,0,0.05)' }}>
        <ProgressBar triggerRefresh={isFeedbackComplete} />
        <div className="p-4">
          {(exerciseError || feedbackError) && <ErrorMessage error={exerciseError || feedbackError} />}
          {!exerciseError && !feedbackError && <>
            <Instructions instructions={exercise?.instructions} />
            {exercise?.prompt && <Prompt prompt={exercise.prompt} />}
            {exercise?.imageUrl && exercise?.imageDescription && <Image url={exercise.imageUrl} />}
            {exercise?.imageUrl1 && exercise?.imageDescription1 && exercise?.imageUrl2 && exercise?.imageDescription2 && <Images urls={[exercise.imageUrl1, exercise.imageUrl2]} />}
            {!feedback && exercise?.timeLimit && <TimeCount currentTime={recordingTime} targetTimeRange={exercise.timeLimit} />}
            {!feedback && <AudioRecorder onStopRecording={handleStopRecording} isSubmitting={false} onTimeUpdate={handleTimeUpdate} />}
            {feedback && <CorrectedResponse text={feedback.correction} />}
            {feedback && <FeedbackSpeaking feedback={feedback} />}
          </>}
          </div>
        </div>
        <LikeExercise exerciseId={exerciseId} reset={likeReset} />
        <ExerciseNavigation
          onBack={handleBack}
          onNext={handleNext}
          disableBack={disableBack}
          triggerRefresh={exercise !== null} />
    </div>
  );
};

export default SpeakingExercise;
