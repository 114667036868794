import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import logo from '../assets/logo_with_ostrich_black.png'; // Import your logo
import videoFile from '../assets/intro.mp4'; // Import your video
import thumbnail from '../assets/intro_poster.png';
import USPs from './USPs';

const Hero = ({ user }) => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <div className="px-4 mt-5 text-center border-bottom">
      <img 
        src={logo} 
        alt="Logo" 
        className="img-fluid mb-4" 
        style={{ maxWidth: '475px', width: '90%', height: 'auto' }} // Max width for desktop, full width for mobile
      />
      <div className="col-lg-6 col-md-8 mx-auto">
        {/* Use the t function to translate the subtitle */}
        <p className="lead display-6 mb-3">{t('subtitle')}</p>
      </div>
      <USPs />
      <div className="overflow-hidden">
        <div className="container pb-2 mb-3 px-0">
          <video
            src={videoFile}
            className="img-fluid border rounded-3 shadow-lg"
            alt="Fluentious"
            style={{ width: '100%', height: 'auto', maxWidth: '700px' }} // Max width for desktop, full width for mobile
            controls
            loading="lazy"
            poster={thumbnail}
          >
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="d-grid gap-3 d-sm-flex justify-content-sm-center mb-5">
          <Link 
            to="/signup" 
            className="btn btn-primary btn-lg px-4 fs-4"
          >
            {t('sign_up')}
          </Link>
          <Link 
            to="/#testimonials" 
            className="btn btn-outline-primary btn-lg px-4 fs-4"
          >
            {t('learn_more')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
