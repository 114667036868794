import React from 'react';
import { useTranslation } from 'react-i18next';

const ExerciseTitle = ({ api }) => {
  const { t } = useTranslation();

  const [category, exerciseType] = api.split("/").slice(6, 8);
  const translatedCategory = t(category);
  const translatedExerciseType = t(exerciseType);

  return (
    <div className="d-flex justify-content-center align-items-center mb-4">
      <h1 className="text-center" style={{ margin: 0, verticalAlign: 'middle' }}>
        {`${translatedCategory} / ${translatedExerciseType}`}
      </h1>
    </div>
  );
};

export default ExerciseTitle;
