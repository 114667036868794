import React from 'react';
import { useTranslation } from 'react-i18next';

const WordCount = ({ text, targetWordCount }) => {
  const { t } = useTranslation();
  
  const currentWordCount = text
    .trim()
    .replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags
    .split(/\s+/)
    .filter(word => word.length > 0)
    .length;

  // Parse the target word count range
  const [min, max] = targetWordCount.split('-').map(Number);
  
  // Calculate progress percentage (capped at 100%)
  const progress = Math.min((currentWordCount / max) * 100, 100);
  
  // Determine the status and styling
  let statusColor = '';
  let progressBarColor = '';
  let animation = '';
  
  if (currentWordCount < min) {
    statusColor = '#dc3545';
    progressBarColor = '#ffd7d9';
  } else if (currentWordCount > max) {
    statusColor = '#dc3545';
    progressBarColor = '#dc3545';
    animation = 'pulse 2s infinite';
  } else {
    statusColor = '#198754';
    progressBarColor = '#198754';
  }

  return (
    <div className="mt-2 mb-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div style={{ fontSize: '0.9rem', color: '#6c757d' }}>
          {t('target_range', { min, max })}
        </div>
        <div 
          style={{ 
            fontSize: '0.9rem',
            fontWeight: '500',
            color: statusColor,
            transition: 'color 0.3s ease'
          }}
        >
          {currentWordCount}
        </div>
      </div>

      <div 
        style={{ 
          height: '4px',
          backgroundColor: '#e9ecef',
          borderRadius: '2px',
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        <div 
          style={{
            position: 'absolute',
            left: `${(min / max) * 100}%`,
            right: `${100 - (max / max) * 100}%`,
            height: '100%',
            borderLeft: '2px solid #adb5bd',
            borderRight: '2px solid #adb5bd',
            zIndex: 2
          }}
        />
        <div 
          style={{
            width: `${progress}%`,
            height: '100%',
            backgroundColor: progressBarColor,
            transition: 'all 0.3s ease',
            animation: animation,
            position: 'relative',
            zIndex: 1
          }}
        />
      </div>

      <style>
        {`
          @keyframes pulse {
            0% { opacity: 1; }
            50% { opacity: 0.7; }
            100% { opacity: 1; }
          }
        `}
      </style>
    </div>
  );
};

export default WordCount; 