import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import styles from '../styles/SignupPage.module.css'; // Import custom CSS module
import logo from '../assets/logo_with_ostrich_black.png'; // Import your logo
import { useTranslation } from 'react-i18next'; // Import i18next for translations

const SignupPage = () => {
  const { user, register } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize translation hook
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (user) {
      navigate('/home');
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await register(name, email, password);
      setStatusMessage(data.message || t('signup_status_success'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || t('signup_error_failure'));
      setStatusMessage('');
    }
  };

  return (
    <div className={`d-flex align-items-center py-4 bg-body-tertiary vh-100 ${styles.formContainer}`}>
      <main className={`form-signup w-100 m-auto ${styles.formSignup}`}>
        <form onSubmit={handleSubmit}>
          <img className="mb-4" src={logo} alt="Logo" width="110" />
          <h1 className="h3 mb-3 fw-normal">{t('signup_page_title')}</h1>

          {statusMessage && <div className="alert alert-success" role="alert">{statusMessage}</div>}
          {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}

          <div className={`form-floating ${styles.formFloating}`}>
            <input
              type="text"
              className="form-control"
              id="floatingName"
              placeholder={t('signup_name_label')}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label htmlFor="floatingName">{t('signup_name_label')}</label>
          </div>
          <div className={`form-floating ${styles.formFloating}`}>
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder={t('signup_email_label')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="floatingInput">{t('signup_email_label')}</label>
          </div>
          <div className={`form-floating ${styles.formFloating}`}>
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder={t('signup_password_label')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="floatingPassword">{t('signup_password_label')}</label>
          </div>

          <button className="btn btn-primary w-100 py-2" type="submit">{t('signup_button')}</button>
          <p className="mt-5 mb-3 text-body-secondary">© 2025 Fluentious</p>
        </form>
      </main>
    </div>
  );
};

export default SignupPage;
