import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const AudioPlayer = ({ audioChunks, onHighlight }) => {
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const audioRef = useRef(null);
  const chunksRef = useRef([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pausedTime, setPausedTime] = useState(0);

  useEffect(() => {
    if (!audioChunks?.length) {
      setIsLoading(true);
      setCurrentChunkIndex(0);
      setIsPlaying(false);
      if (audioRef.current) {
        audioRef.current.pause();
        if (audioRef.current.src) {
          URL.revokeObjectURL(audioRef.current.src);
          audioRef.current.src = '';
        }
      }
      chunksRef.current = [];
      return;
    }

    const processedChunks = audioChunks.map(chunk => ({
      ...chunk,
      audioBlob: new Blob(
        [Uint8Array.from(atob(chunk.audio), c => c.charCodeAt(0))], 
        { type: 'audio/mp3' }
      )
    }));
    chunksRef.current = processedChunks.sort((a, b) => a.timestamp - b.timestamp);
    setIsLoading(false);
  }, [audioChunks]);

  const playNextChunk = () => {
    if (currentChunkIndex < chunksRef.current.length - 1) {
      setPausedTime(0);
      setCurrentChunkIndex(prev => prev + 1);
      const nextChunk = chunksRef.current[currentChunkIndex + 1];
      if (audioRef.current.src) {
        URL.revokeObjectURL(audioRef.current.src);
      }
      audioRef.current.src = URL.createObjectURL(nextChunk.audioBlob);
      audioRef.current.play();
      if (onHighlight) onHighlight(nextChunk.text);
    } else {
      handleReset();
    }
  };

  const handlePlay = () => {
    if (chunksRef.current.length === 0) return;
    
    setIsPlaying(true);
    const currentChunk = chunksRef.current[currentChunkIndex];
    
    if (audioRef.current.src) {
      URL.revokeObjectURL(audioRef.current.src);
    }
    audioRef.current.src = URL.createObjectURL(currentChunk.audioBlob);
    
    audioRef.current.currentTime = pausedTime;
    audioRef.current.play();
    if (onHighlight) onHighlight(currentChunk.text);
  };

  const handlePause = () => {
    setIsPlaying(false);
    setPausedTime(audioRef.current.currentTime);
    audioRef.current.pause();
    if (onHighlight) onHighlight('');
  };

  const handleReset = () => {
    setIsPlaying(false);
    setCurrentChunkIndex(0);
    setPausedTime(0);
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    if (audioRef.current.src) {
      URL.revokeObjectURL(audioRef.current.src);
      audioRef.current.src = '';
    }
    if (onHighlight) onHighlight('');
  };

  // Cleanup URLs on unmount
  useEffect(() => {
    return () => {
      if (audioRef.current?.src) {
        URL.revokeObjectURL(audioRef.current.src);
      }
    };
  }, []);

  return (
    <div className="audio-player mt-3 mb-3">
      <audio 
        ref={audioRef}
        onEnded={playNextChunk}
        onError={(e) => console.error('Audio error:', e)}
      />
      <div className="btn-group">
        {isLoading ? (
          <button className="btn btn-success" disabled>
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            {t('loading_audio')}
          </button>
        ) : (
          <>
            <button 
              className="btn btn-success" 
              onClick={isPlaying ? handlePause : handlePlay}
              disabled={!chunksRef.current.length}
            >
              <i className={`bi bi-${isPlaying ? 'pause' : 'play'}-fill`}></i>
              {isPlaying ? ` ${t('pause')}` : ` ${t('play')}`}
            </button>
            <button 
              className="btn btn-secondary" 
              onClick={handleReset}
              disabled={!chunksRef.current.length}
            >
              <i className="bi bi-arrow-counterclockwise"></i> {t('reset')}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default AudioPlayer;
