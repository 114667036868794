import React, { useState, useEffect } from "react";
import HeadingItem from "./HeadingItem";

const areAllHeadingsSelected = (answers, passage) => {
  if (!passage || !Array.isArray(passage)) return false;
  return passage.every((section) => answers[section.text]);
};

const HeadingsList = ({ passage, headings, onHighlightChange, onComplete, showResults }) => {
  const [answers, setAnswers] = useState({});
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [shuffledHeadings, setShuffledHeadings] = useState([]);
  const [hasShuffled, setHasShuffled] = useState(false);

  // Shuffle headings only once when passage becomes available
  useEffect(() => {
    if (headings?.length && passage?.length && !hasShuffled) {
      setShuffledHeadings([...headings].sort(() => Math.random() - 0.5));
      setHasShuffled(true);
    }
  }, [headings, passage, hasShuffled]);

  // Check if all available sections have been answered
  useEffect(() => {
    if (passage && Array.isArray(passage)) {
      setSubmitDisabled(!areAllHeadingsSelected(answers, passage));
    }
  }, [answers, passage]);

  const handleAnswerChange = (paragraphText, selectedHeading) => {
    setAnswers(prev => ({ ...prev, [paragraphText]: selectedHeading }));
  };

  const handleHoverChange = (reference) => {
    if (showResults && reference) {
      onHighlightChange(reference);
    } else {
      onHighlightChange("");
    }
  };

  const handleCompletion = () => {
    if (!passage || !Array.isArray(passage)) return;
    
    const score = passage.filter(section => 
      answers[section.text] === section.heading
    ).length / passage.length;
    
    // Convert answers object to array format based on passage order
    const answersArray = Object.entries(answers)
      .sort(([a], [b]) => {
        // Find indices in passage array to maintain correct order
        const indexA = passage.findIndex(section => section.text === a);
        const indexB = passage.findIndex(section => section.text === b);
        return indexA - indexB;
      })
      .map(([_, heading]) => heading);

    // Pass both answersArray and score to parent
    onComplete(answersArray, score);
  };

  // Return null if no data yet
  if (!passage || !Array.isArray(passage) || !shuffledHeadings.length) {
    return null;
  }

  return (
    <div>
      {passage.map((section, index) => {
        if (!section?.text || !section?.heading) return null;

        return (
          <HeadingItem
            key={index}
            paragraphLabel={String.fromCharCode(65 + index)}
            section={section}
            headings={shuffledHeadings}
            selected={answers[section.text] || ""}
            onChange={(selected) => handleAnswerChange(section.text, selected)}
            onHoverChange={(ref) => handleHoverChange(ref)}
            showResults={showResults}
          />
        );
      })}

      {!showResults && shuffledHeadings.length > 0 && (
        <button 
          onClick={handleCompletion} 
          disabled={isSubmitDisabled} 
          className="btn btn-primary mt-3"
        >
          Submit
        </button>
      )}
    </div>
  );
};

export default HeadingsList;
