import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useExerciseLimits } from '../hooks/useExerciseLimits';

const HomePage = () => {
  const { user } = useContext(AuthContext);
  const { isLimitReached, isCheckingLimit } = useExerciseLimits();

  if (isCheckingLimit) {
    return (
      <div className="text-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
  }

  if (isLimitReached) {
    return <Navigate to="/premium" />;
  }

  if (user?.hasCompletedSetup) {
    return <Navigate to="/practice" />;
  }

  return null;
};

export default HomePage;
