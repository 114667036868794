import React, { useEffect, useRef, useState, useCallback } from 'react';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import ExerciseTitle from './ExerciseTitle.js';
import { useTranslation } from 'react-i18next';
import Toolbar from './Toolbar.js';
import Transcript from './Transcript.js'; // Import the Transcript component

const RealtimeSpeakingExercise = ({ api }) => {
  const { t } = useTranslation();
  const [isConnected, setIsConnected] = useState(false);
  const [items, setItems] = useState([]);
  const clientRef = useRef(null);
  const wavRecorderRef = useRef(null);
  const wavStreamPlayerRef = useRef(null);

  const cleanupResources = useCallback(() => {
    if (clientRef.current) { clientRef.current.disconnect(); clientRef.current = null; }
    if (wavRecorderRef.current) { wavRecorderRef.current.quit(); wavRecorderRef.current = null; }
    if (wavStreamPlayerRef.current) { wavStreamPlayerRef.current = null; }
  }, []);

  const disconnectConversation = useCallback(() => {
    setIsConnected(false);
    setItems([]);
    cleanupResources();
  }, [cleanupResources]);

  const connectConversation = useCallback(async () => {
    cleanupResources();

    clientRef.current = new RealtimeClient({
      url: process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || 'ws://localhost:8081'
    });
    wavRecorderRef.current = new WavRecorder({ sampleRate: 24000 });
    wavStreamPlayerRef.current = new WavStreamPlayer({ sampleRate: 24000 });

    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    try {
      await wavRecorder.begin();
      await wavStreamPlayer.connect();
      await client.connect();

      // Dummy call to update session to be intercepted by the server
      // API key is in the form /api/exercises/${exam}/${targetLanguage}/${level}/${section}/${exercise}
      client.updateSession({ instructions: api });

      // Send an initial user message to prompt the AI
      client.sendUserMessageContent([{ type: 'input_text', text: '' }]);

      await wavRecorder.record((data) => client.appendInputAudio(data.mono));

      client.on('conversation.updated', async ({ item, delta }) => {
        if (delta?.audio) {
          wavStreamPlayer.add16BitPCM(delta.audio, item.id);
        }
        setItems(client.conversation.getItems());
      });

      client.on('error', (error) => {
        console.error('Realtime client error:', error);
        disconnectConversation();
      });

      client.on('conversation.interrupted', async () => {
        const trackSampleOffset = await wavStreamPlayer.interrupt();
        if (trackSampleOffset?.trackId) {
          const { trackId, offset } = trackSampleOffset;
          await client.cancelResponse(trackId, offset);
        }
      });

      setIsConnected(true);
    } catch (error) {
      console.error('Error connecting to conversation:', error);
      disconnectConversation();
    }
  }, [cleanupResources, api]); // Add api to the dependency array

  useEffect(() => {
    // Only cleanup resources when api changes, don't auto-connect
    return () => {
      cleanupResources();
    };
  }, [api, cleanupResources]);

  const handleToggleRecording = () => {
    if (isConnected) {
      disconnectConversation();
    } else {
      connectConversation();
    }
  };

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <ExerciseTitle api={api} />
      <Toolbar data={{
        targetLanguage: api.split('/')[4],
        level: api.split('/')[5],
        section: api.split('/')[6],
        exercise: api.split('/')[7]
      }} />
      <button 
        onClick={handleToggleRecording}
        className={`btn ${isConnected ? 'btn-secondary' : 'btn-danger'} mb-3`}
      >
        {isConnected ? t('stop_exercise') : t('start_exercise')}
      </button>
      {items.length > 0 && <Transcript 
        conversation={items.map(item => ({
          speaker: item.role === 'assistant' ? 'Examiner' : 'User',
          text: item.formatted.transcript || item.formatted.text
        }))}
      />}
    </div>
  );
};

export default RealtimeSpeakingExercise;
