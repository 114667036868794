import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import constants from '../config/constants';

const Pricing = () => {
  const { t } = useTranslation();

  return (
    <div className="container col-xxl-8 px-4 pt-5">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h2 className="display-4 fw-normal text-body-emphasis">{t('pricing_title')}</h2>
        <p className="fs-5 text-body-secondary">
          {t('pricing_subtitle')}
        </p>
      </div>
      <div className="row row-cols-1 row-cols-md-2 g-4 text-center mb-3">
        {/* Basic Plan */}
        <div className="col d-flex">
          <div className="card w-100 rounded-3 shadow-sm h-100 d-flex flex-column">
            <div className="card-header py-3">
              <h4 className="my-0 fw-normal">{t('pricing_basic_plan')}</h4>
            </div>
            <div className="card-body d-flex flex-column">
              {/* Price Section */}
              <div className="mb-4 mt-4 d-flex flex-column align-items-center justify-content-center" style={{ height: '60px' }}>
                <h1 className="card-title pricing-card-title fw-normal" style={{ fontSize: '2.5rem' }}>{t('pricing_basic_price')}</h1>
                <p className="text-secondary mb-0 fs-5">{t('pricing_basic_trial_period', { days: constants.trialDays })}</p>
              </div>
              {/* Features Section */}
              <ul className="list-unstyled mb-4 flex-grow-1 d-flex flex-column justify-content-center" style={{ height: '160px', fontSize: '1.1rem' }}>
                <li className="mb-1">
                  <i className="bi bi-pencil-square me-2"></i>
                  {t('pricing_exercises', { count: constants.exerciseLimits.basic })}
                </li>
                <li className="mb-1">
                  <i className="bi bi-chat-left-text me-2"></i>
                  {t('pricing_ai_feedback')}
                </li>
                <li className="mb-1">
                  <i className="bi bi-graph-up me-2"></i>
                  {t('pricing_basic_analytics')}
                </li>
              </ul>
              {/* CTA Section */}
              <div className="mt-auto d-flex align-items-center justify-content-center" style={{ height: '40px' }}>
                <Link to="/signup" className="w-100 btn btn-lg btn-outline-primary">{t('pricing_basic_button')}</Link>
              </div>
            </div>
          </div>
        </div>
        {/* Premium Plan */}
        <div className="col d-flex">
          <div className="card w-100 rounded-3 shadow-sm border-primary h-100 d-flex flex-column">
            <div className="card-header py-3 text-bg-primary border-primary position-relative">
              <h4 className="my-0 fw-normal">{t('pricing_premium_plan')}</h4>
              <span className="position-absolute badge rounded-pill bg-danger" 
                    style={{ 
                      fontSize: '1.2rem',
                      fontWeight: '700',
                      padding: '0.9rem 1.3rem',
                      top: '-15px',
                      right: '-20px',
                      transform: 'rotate(12deg)',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.25)'
                    }}>
                {t('pricing_premium_discount_percentage', { percentage: constants.prices.discount })}
              </span>
            </div>
            <div className="card-body d-flex flex-column">
              {/* Updated Price Section */}
              <div className="mb-4 mt-4 d-flex flex-column align-items-center justify-content-center" style={{ height: '60px' }}>
                <h1 className="card-title pricing-card-title fw-normal mb-1" style={{ fontSize: '2.5rem' }}>
                  <span className="text-decoration-line-through text-muted me-2">{constants.prices.standard}</span>
                  {constants.prices.discounted}
                  <small className="text-body-secondary fw-light">{t('pricing_premium_duration')}</small>
                </h1>
                <p className="text-danger mb-0 fs-5">{t('pricing_premium_launch_offer')}</p>
              </div>
              {/* Features Section for Premium */}
              <ul className="list-unstyled mb-4 flex-grow-1 d-flex flex-column justify-content-center" style={{ height: '160px', fontSize: '1.1rem' }}>
                <li className="mb-1">
                  <i className="bi bi-pencil-square me-2"></i>
                  {t('pricing_exercises', { count: constants.exerciseLimits.premium })} <small className="text-muted">{t('pricing_premium_duration')}</small>
                </li>
                <li className="mb-1">
                  <i className="bi bi-chat-left-text me-2"></i>
                  {t('pricing_ai_feedback')}
                </li>
                <li className="mb-1">
                  <i className="bi bi-graph-up me-2"></i>
                  {t('pricing_advanced_analytics')}
                </li>
                <li className="mb-1">
                  <i className="bi bi-envelope me-2"></i>
                  {t('pricing_email_support')}
                </li>
              </ul>
              {/* CTA Section */}
              <div className="mt-auto d-flex align-items-center justify-content-center" style={{ height: '40px' }}>
                <Link to="/signup" className="w-100 btn btn-lg btn-primary">{t('pricing_premium_button')}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
