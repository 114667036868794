import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ExerciseNavigation = ({ onBack, onNext, disableBack, disableNext, triggerRefresh }) => {
  const { t } = useTranslation();
  const [exerciseCount, setExerciseCount] = useState(null);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await fetch('/api/exercises/counts', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (!response.ok) throw new Error('Failed to fetch exercise counts');
        const data = await response.json();
        setExerciseCount(data);
      } catch (error) {
        console.error('Error fetching exercise counts:', error);
      }
    };

    fetchCounts();
  }, [triggerRefresh]);

  return (
    <div className="mt-4 mb-4 text-center">
      <div className="mt-4 gap-3 gap-md-0 d-flex flex-column flex-md-row align-items-center justify-content-center">
        <>
          {!disableBack && 
            <button className="btn btn-outline-primary btn me-3 order-2 order-md-1" onClick={onBack} style={{ fontSize: '1.15rem' }}>
              <i className="bi bi-reply me-2"></i> {t('back')}
            </button>
          }
          {!disableNext && 
            <button className="btn btn-primary btn order-1 order-md-2" onClick={onNext} style={{ fontSize: '1.15rem' }}>
              <i className="bi bi-arrow-right-circle me-2"></i> 
              {t('next_exercise')}
              {exerciseCount && (
                <span className="ms-2 opacity-75">
                  ({t('exercises_left', { count: exerciseCount.remaining })})
                </span>
              )}
            </button>
          }
        </>
      </div>
    </div>
  );
};

export default ExerciseNavigation;
