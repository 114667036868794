import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from './LoadingSpinner';

const SubmitButton = ({ onClick, isSubmitting }) => {
  const { t } = useTranslation(); // Initialize the translation hook

  return (
    <div className="d-flex align-items-center">
      <button className="btn btn-success" onClick={onClick} disabled={isSubmitting}>
        {t('submit')} {/* Translate "Submit" */}
      </button>
      {isSubmitting && <LoadingSpinner inline={true} />}
    </div>
  );
};

export default SubmitButton;
