import { useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import { useExerciseLimits } from '../hooks/useExerciseLimits';
import ReadingExercise from "./ReadingExercise";
import WritingExercise from "./WritingExercise";
import ListeningExercise from "./ListeningExercise";
import SpeakingExercise from "./SpeakingExercise";
import RealtimeSpeakingExercise from './RealtimeSpeakingExercise';

const Exercise = ({ section: sectionProp, exercise: exerciseProp, onBack, onNext, disableBack }) => {
  const { user } = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const { isLimitReached, isCheckingLimit } = useExerciseLimits();
  
  const section = sectionProp || params.section;
  const exercise = exerciseProp || params.exercise;
  
  const level = user.proficiencyLevel;
  const targetLanguage = user.targetLanguage;
  const topic = user.topic;

  const api = `/api/exercises/general/${targetLanguage}/${level}/${section}/${exercise}/${topic}`;

  if (isCheckingLimit) {
    return <div className="text-center mt-5">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>;
  }

  if (isLimitReached) {
    navigate('/premium');
    return null;
  }

  switch (section) {
    case "reading":
      return <ReadingExercise api={api} onBack={onBack} onNext={onNext} disableBack={disableBack} />;
    case "writing":
      return <WritingExercise api={api} onBack={onBack} onNext={onNext} disableBack={disableBack} />;
    case "listening":
      return <ListeningExercise api={api} onBack={onBack} onNext={onNext} disableBack={disableBack} />;
    case "speaking":
      if (exercise === 'interview' || exercise === 'discussion') 
        return <RealtimeSpeakingExercise api={api} onBack={onBack} onNext={onNext} disableBack={disableBack} />;
      else 
        return <SpeakingExercise api={api} onBack={onBack} onNext={onNext} disableBack={disableBack} />;
    default:
      return null;
  }
};

export default Exercise;
