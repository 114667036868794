import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { updateProficiencyLevel, updateTargetLanguage, updateTopic, updateKnownLanguage, completeSetup, updateExerciseTypes } from '../services/userService';
import { useTranslation } from 'react-i18next';
import languages from '../config/languages';
import styles from '../styles/Setup.module.css';

const Setup = () => {
  const { t, i18n } = useTranslation();
  const { user, setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    targetLanguage: user?.targetLanguage || 'EN',
    proficiencyLevel: user?.proficiencyLevel || 'A1',
    exerciseTypes: user?.exerciseTypes || ['reading', 'listening', 'writing', 'speaking'],
    topic: user?.topic || 'general',
    knownLanguage: user?.knownLanguage || 'EN'
  });

  // Set initial language
  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    const browserLanguage = navigator.language?.split('-')[0].toUpperCase();
    const initialLanguage = user?.knownLanguage || storedLanguage || 
      (languages.some(lang => lang.code === browserLanguage) ? browserLanguage : 'EN');

    setFormData(prev => ({ ...prev, knownLanguage: initialLanguage }));
    i18n.changeLanguage(initialLanguage.toLowerCase());
    
    if (!storedLanguage) {
      localStorage.setItem('language', initialLanguage);
    }
  }, [user]);

  // Generic field update handler
  const handleFieldUpdate = async (field, value, updateFunction) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await updateFunction(value, token);
      
      setFormData(prev => ({ ...prev, [field]: value }));
      setUser(prev => ({ ...prev, [field]: value }));
      
      if (field === 'knownLanguage') {
        i18n.changeLanguage(value.toLowerCase());
        localStorage.setItem('language', value);
      }
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    const updateFunctions = {
      targetLanguage: updateTargetLanguage,
      proficiencyLevel: updateProficiencyLevel,
      topic: updateTopic,
      knownLanguage: updateKnownLanguage
    };
    
    handleFieldUpdate(id, value, updateFunctions[id]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await completeSetup(token);
      setUser(prev => ({ ...prev, hasCompletedSetup: true }));
    } catch (error) {
      console.error("Error completing setup:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExerciseTypesChange = (type) => {
    setFormData(prev => {
      const updatedTypes = prev.exerciseTypes.includes(type)
        ? prev.exerciseTypes.filter(t => t !== type)
        : [...prev.exerciseTypes, type];
      
      // Ensure at least one type is selected
      if (updatedTypes.length === 0) {
        return prev;
      }
      
      handleFieldUpdate('exerciseTypes', updatedTypes, updateExerciseTypes);
      return { ...prev, exerciseTypes: updatedTypes };
    });
  };

  const formFields = [
    {
      id: 'knownLanguage',
      icon: 'person-fill',
      label: t('setup_known_language'),
      options: languages.map(lang => ({ 
        value: lang.code, 
        label: lang.name 
      }))
    },
    {
      id: 'targetLanguage',
      icon: 'globe',
      label: t('setup_target_language'),
      options: languages.map(lang => ({ 
        value: lang.code, 
        label: lang.name 
      }))
    },
    {
      id: 'proficiencyLevel',
      icon: 'bar-chart',
      label: t('setup_level'),
      options: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map(level => ({
        value: level,
        label: `${t(`level_${level.toLowerCase()}`)} (${level})`
      }))
    },
    {
      id: 'exerciseTypes',
      icon: 'list-check',
      label: t('setup_exercise_types'),
      type: 'checkboxes',
      options: [
        { value: 'reading', label: t('reading') },
        { value: 'listening', label: t('listening') },
        { value: 'writing', label: t('writing') },
        { value: 'speaking', label: t('speaking') }
      ]
    },
    {
      id: 'topic',
      icon: 'book',
      label: t('setup_topic'),
      options: [
        { value: 'general', label: t('general') },
        { value: 'business', label: t('business') },
        { value: 'technology', label: t('technology') },
        { value: 'science', label: t('science') },
        { value: 'arts', label: t('arts') },
        { value: 'sports', label: t('sports') },
        { value: 'travel', label: t('travel') },
        { value: 'food', label: t('food') },
        { value: 'health', label: t('health') },
        { value: 'education', label: t('education') },
        { value: 'entertainment', label: t('entertainment') },
        { value: 'politics', label: t('politics') },
        { value: 'environment', label: t('environment') },
        { value: 'lifestyle', label: t('lifestyle') },
      ]
    }
  ];

  return (
    <div className="modal d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header position-relative">
            <div className="text-center w-100">
              <h5 className="modal-title fs-3">{t('setup_title')}</h5>
              <p className="text-muted mb-0 mt-2">{t('setup_subtitle')}</p>
            </div>
            <button
              type="button"
              className="btn-close position-absolute top-0 end-0 mt-2 me-2"
              onClick={handleSubmit}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body px-3 px-md-5 pb-md-4">
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-column align-items-center mb-3">
                {formFields.map((field, index) => (
                  <div 
                    className="form-group w-100" 
                    key={field.id}
                    style={{ 
                      marginBottom: index === formFields.length - 1 ? '0.5rem' : '1.25rem' 
                    }}
                  >
                    <div className="row g-0 justify-content-center align-items-center">
                      <div className="col-12 col-md-6 text-center text-md-end">
                        <label 
                          htmlFor={field.id} 
                          className="form-label mb-2 mb-md-0 me-md-3"
                          style={{ marginTop: '0.2rem' }}
                        >
                          <i className={`bi bi-${field.icon} me-2`}></i>
                          {field.label}
                        </label>
                      </div>
                      <div className="col-12 col-md-5 text-center text-md-start">
                        {field.type === 'checkboxes' ? (
                          <table className="mx-auto mx-md-0">
                            <tbody>
                              <tr>
                                <td style={{ paddingRight: '1rem', paddingBottom: '0.5rem' }}>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`${field.id}-reading`}
                                      checked={formData[field.id].includes('reading')}
                                      onChange={() => handleExerciseTypesChange('reading')}
                                      disabled={loading || (formData[field.id].length === 1 && formData[field.id].includes('reading'))}
                                    />
                                    <label className="form-check-label" htmlFor={`${field.id}-reading`}>
                                      {t('reading')}
                                    </label>
                                  </div>
                                </td>
                                <td style={{ paddingBottom: '0.5rem' }}>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`${field.id}-listening`}
                                      checked={formData[field.id].includes('listening')}
                                      onChange={() => handleExerciseTypesChange('listening')}
                                      disabled={loading || (formData[field.id].length === 1 && formData[field.id].includes('listening'))}
                                    />
                                    <label className="form-check-label" htmlFor={`${field.id}-listening`}>
                                      {t('listening')}
                                    </label>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingRight: '1rem' }}>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`${field.id}-writing`}
                                      checked={formData[field.id].includes('writing')}
                                      onChange={() => handleExerciseTypesChange('writing')}
                                      disabled={loading || (formData[field.id].length === 1 && formData[field.id].includes('writing'))}
                                    />
                                    <label className="form-check-label" htmlFor={`${field.id}-writing`}>
                                      {t('writing')}
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`${field.id}-speaking`}
                                      checked={formData[field.id].includes('speaking')}
                                      onChange={() => handleExerciseTypesChange('speaking')}
                                      disabled={loading || (formData[field.id].length === 1 && formData[field.id].includes('speaking'))}
                                    />
                                    <label className="form-check-label" htmlFor={`${field.id}-speaking`}>
                                      {t('speaking')}
                                    </label>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : (
                          <select
                            id={field.id}
                            value={formData[field.id]}
                            onChange={handleChange}
                            className={`form-select ${styles.select}`}
                            disabled={loading}
                          >
                            {field.options.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="text-center text-secondary mb-2">
                {t('setup_note')}
              </div>

              <div className="text-center">
                <button 
                  type="submit" 
                  className="btn btn-primary w-100 w-md-50 mt-3"
                  disabled={loading}
                  style={{ fontSize: '1.15rem', maxWidth: '310px' }}
                >
                  {loading ? t('saving') : t('get_started')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setup;