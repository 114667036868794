import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import basicImage from '../assets/subscription_basic.png';
import premiumImage from '../assets/subscription_premium.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation for translations
import { 
  updateTargetLanguage, 
  updateProficiencyLevel, 
  updateTopic, 
  updateKnownLanguage,
  updateExerciseTypes 
} from '../services/userService';
import languages from '../config/languages';
import styles from '../styles/SettingsPage.module.css';

const SettingsPage = () => {
  const { t, i18n } = useTranslation();
  const { user, setUser, refreshUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    targetLanguage: user?.targetLanguage || 'EN',
    proficiencyLevel: user?.proficiencyLevel || 'A1',
    topic: user?.topic || 'general',
    knownLanguage: user?.knownLanguage || 'EN',
    exerciseTypes: user?.exerciseTypes || ['reading', 'listening', 'writing', 'speaking']
  });
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch subscription status on mount
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('/api/user/subscription-status', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubscriptionStatus(response.data.subscriptionStatus);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  // Generic field update handler
  const handleFieldUpdate = async (field, value, updateFunction) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await updateFunction(value, token);
      
      setFormData(prev => ({ ...prev, [field]: value }));
      setUser(prev => ({ ...prev, [field]: value }));
      
      if (field === 'knownLanguage') {
        i18n.changeLanguage(value.toLowerCase());
        localStorage.setItem('language', value);
      }
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    const updateFunctions = {
      targetLanguage: updateTargetLanguage,
      proficiencyLevel: updateProficiencyLevel,
      topic: updateTopic,
      knownLanguage: updateKnownLanguage
    };
    
    handleFieldUpdate(id, value, updateFunctions[id]);
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/user/cancel-subscription', {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSubscriptionStatus('cancelled');
      refreshUser();
    } catch (error) {
      console.error('Error cancelling subscription:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = () => {
    navigate('/premium');
  };

  // Add handler for exercise types changes
  const handleExerciseTypesChange = (type) => {
    setFormData(prev => {
      const updatedTypes = prev.exerciseTypes.includes(type)
        ? prev.exerciseTypes.filter(t => t !== type)
        : [...prev.exerciseTypes, type];
      
      // Ensure at least one type is selected
      if (updatedTypes.length === 0) {
        return prev;
      }
      
      handleFieldUpdate('exerciseTypes', updatedTypes, updateExerciseTypes);
      return { ...prev, exerciseTypes: updatedTypes };
    });
  };

  // Add the formFields definition before the return statement
  const formFields = [
    {
      id: 'knownLanguage',
      icon: 'person-fill',
      label: t('setup_known_language'),
      options: languages.map(lang => ({ 
        value: lang.code, 
        label: lang.name 
      }))
    },
    {
      id: 'targetLanguage',
      icon: 'globe',
      label: t('setup_target_language'),
      options: languages.map(lang => ({ 
        value: lang.code, 
        label: lang.name 
      }))
    },
    {
      id: 'proficiencyLevel',
      icon: 'bar-chart',
      label: t('setup_level'),
      options: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map(level => ({
        value: level,
        label: `${t(`level_${level.toLowerCase()}`)} (${level})`
      }))
    },
    {
      id: 'exerciseTypes',
      icon: 'list-check',
      label: t('setup_exercise_types'),
      type: 'checkboxes',
      options: [
        { value: 'reading', label: t('reading') },
        { value: 'listening', label: t('listening') },
        { value: 'writing', label: t('writing') },
        { value: 'speaking', label: t('speaking') }
      ]
    },
    {
      id: 'topic',
      icon: 'book',
      label: t('setup_topic'),
      options: [
        { value: 'general', label: t('general') },
        { value: 'business', label: t('business') },
        { value: 'technology', label: t('technology') },
        { value: 'science', label: t('science') },
        { value: 'arts', label: t('arts') },
        { value: 'sports', label: t('sports') },
        { value: 'travel', label: t('travel') },
        { value: 'food', label: t('food') },
        { value: 'health', label: t('health') },
        { value: 'education', label: t('education') },
        { value: 'entertainment', label: t('entertainment') },
        { value: 'politics', label: t('politics') },
        { value: 'environment', label: t('environment') },
        { value: 'lifestyle', label: t('lifestyle') },
      ]
    }
  ];

  return (
    <div className="container mt-4">
      <h1 className="text-center mb-4">{t('settings')}</h1>
      
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              {/* User Info Section */}
              <div className="text-center mb-4">
                <p className="fs-4 mb-1">{user.name}</p>
                <p className="text-muted mb-3 fs-6">{user.email}</p>
                
                {/* Subscription Status */}
                {subscriptionStatus === 'active' ? (
                  <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
                    <img 
                      src={premiumImage} 
                      alt={t('premium_subscription')} 
                      style={{ height: '20px' }}
                      className="mb-3 mb-md-0" 
                    />
                    <button
                      type="button"
                      className="btn btn-danger mx-md-3"
                      onClick={handleCancelSubscription}
                      disabled={loading}
                    >
                      {t('cancel_subscription')}
                    </button>
                  </div>
                ) : (
                  <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
                    <img 
                      src={basicImage} 
                      alt={t('basic_subscription')} 
                      style={{ height: '18px' }}
                      className="mb-3 mb-md-0" 
                    />
                    <button
                      type="button"
                      className="btn btn-success mx-md-3"
                      onClick={handleSubscribe}
                      disabled={loading}
                    >
                      {t('upgrade')}
                    </button>
                  </div>
                )}
              </div>

              {/* Language Settings */}
              <hr className="my-4" />
              
              <div className="d-flex flex-column align-items-center">
                {formFields.map((field, index) => (
                  <div 
                    className="form-group w-100" 
                    key={field.id}
                    style={{ 
                      marginBottom: index === formFields.length - 1 ? '0.5rem' : '1.25rem' 
                    }}
                  >
                    <div className="row g-0 justify-content-center align-items-center">
                      <div className="col-12 col-md-5 text-center text-md-end">
                        <label 
                          htmlFor={field.id} 
                          className="form-label mb-2 mb-md-0 me-md-3"
                          style={{ marginTop: '0.2rem' }}
                        >
                          <i className={`bi bi-${field.icon} me-2`}></i>
                          {field.label}
                        </label>
                      </div>
                      <div className="col-12 col-md-4 text-center text-md-start">
                        {field.type === 'checkboxes' ? (
                          <table className="mx-auto mx-md-0">
                            <tbody>
                              <tr>
                                <td style={{ paddingRight: '1rem', paddingBottom: '0.5rem' }}>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`${field.id}-reading`}
                                      checked={formData[field.id].includes('reading')}
                                      onChange={() => handleExerciseTypesChange('reading')}
                                      disabled={loading || (formData[field.id].length === 1 && formData[field.id].includes('reading'))}
                                    />
                                    <label className="form-check-label" htmlFor={`${field.id}-reading`}>
                                      {t('reading')}
                                    </label>
                                  </div>
                                </td>
                                <td style={{ paddingBottom: '0.5rem' }}>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`${field.id}-listening`}
                                      checked={formData[field.id].includes('listening')}
                                      onChange={() => handleExerciseTypesChange('listening')}
                                      disabled={loading || (formData[field.id].length === 1 && formData[field.id].includes('listening'))}
                                    />
                                    <label className="form-check-label" htmlFor={`${field.id}-listening`}>
                                      {t('listening')}
                                    </label>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingRight: '1rem' }}>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`${field.id}-writing`}
                                      checked={formData[field.id].includes('writing')}
                                      onChange={() => handleExerciseTypesChange('writing')}
                                      disabled={loading || (formData[field.id].length === 1 && formData[field.id].includes('writing'))}
                                    />
                                    <label className="form-check-label" htmlFor={`${field.id}-writing`}>
                                      {t('writing')}
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`${field.id}-speaking`}
                                      checked={formData[field.id].includes('speaking')}
                                      onChange={() => handleExerciseTypesChange('speaking')}
                                      disabled={loading || (formData[field.id].length === 1 && formData[field.id].includes('speaking'))}
                                    />
                                    <label className="form-check-label" htmlFor={`${field.id}-speaking`}>
                                      {t('speaking')}
                                    </label>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : (
                          <select
                            id={field.id}
                            value={formData[field.id]}
                            onChange={handleChange}
                            className={`form-select ${styles.select}`}
                            disabled={loading}
                          >
                            {field.options.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-4 mb-4 text-center">
        <button 
          className="btn btn-primary" 
          onClick={() => navigate('/practice')}
          style={{ fontSize: '1.15rem' }}
        >
          <i className="bi bi-reply me-2"></i> {t('back')}
        </button>
      </div>
    </div>
  );
};

export default SettingsPage;
