import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Link, Navigate } from 'react-router-dom';
import styles from '../styles/ExercisesPage.module.css';
import { useTranslation } from 'react-i18next';
import { useExerciseLimits } from '../hooks/useExerciseLimits';

const readingExercises = [
  { name: 'multiple_choice', exercise: 'multiple_choice', icon: 'bi-list-check' },
  { name: 'true_false_not_given', exercise: 'true_false_not_given', icon: 'bi-check-circle' },
  { name: 'matching_headings', exercise: 'matching_headings', icon: 'bi-text-paragraph' },
  { name: 'gap_filling', exercise: 'gap_filling', icon: 'bi-distribute-vertical' },
  { name: 'sentence_completion', exercise: 'sentence_completion', icon: 'bi-pencil-square' },
  { name: 'table_completion', exercise: 'table_completion', icon: 'bi-table' },
  { name: 'note_completion', exercise: 'note_completion', icon: 'bi-journal-text' },
  { name: 'short_answer_questions', exercise: 'short_answer_questions', icon: 'bi-question-circle' },
  { name: 'summary_completion', exercise: 'summary_completion', icon: 'bi-funnel' },
];

const listeningExercises = [
  { name: 'multiple_choice', exercise: 'multiple_choice', icon: 'bi-list-check' },
  { name: 'true_false_not_given', exercise: 'true_false_not_given', icon: 'bi-check-circle' },
  { name: 'matching_speakers', exercise: 'matching_speakers', icon: 'bi-people' },
  { name: 'table_completion', exercise: 'table_completion', icon: 'bi-table' },
  { name: 'sentence_completion', exercise: 'sentence_completion', icon: 'bi-pencil-square' },
  { name: 'note_completion', exercise: 'note_completion', icon: 'bi-journal-text' },
  { name: 'short_answer_questions', exercise: 'short_answer_questions', icon: 'bi-question-circle' },
  { name: 'summary_completion', exercise: 'summary_completion', icon: 'bi-funnel' },
];

const speakingExercises = [
  { name: 'picture_description', exercise: 'picture_description', icon: 'bi-image' },
  { name: 'picture_comparison', exercise: 'picture_comparison', icon: 'bi-images' },
  { name: 'monologue', exercise: 'monologue', icon: 'bi-chat-dots' },
  { name: 'compare_contrast', exercise: 'compare_contrast', icon: 'bi-arrows-expand-vertical' },
  { name: 'argumentation', exercise: 'argumentation', icon: 'bi-chat-right-quote' },
  // { name: 'interview', exercise: 'interview', icon: 'bi-people' },
  // { name: 'discussion', exercise: 'discussion', icon: 'bi-people' },
];

const writingExercises = [
  { name: 'table_description', exercise: 'table_description', icon: 'bi-table' },
  { name: 'graph_description', exercise: 'graph_description', icon: 'bi-graph-up' },
  { name: 'chart_description', exercise: 'chart_description', icon: 'bi-bar-chart' },
  { name: 'text_summarisation', exercise: 'text_summarisation', icon: 'bi-funnel' },
  { name: 'letter', exercise: 'letter', icon: 'bi-envelope-paper' },
  { name: 'essay', exercise: 'essay', icon: 'bi-pencil-square' },
];

const ExercisesPage = () => {
  const { user, loading } = useContext(AuthContext);
  const { t } = useTranslation();
  const { isLimitReached, isCheckingLimit } = useExerciseLimits();

  const renderExercises = (exercises, section) => (
    <ul className="list-group mt-3">
      {exercises.map((exercise, index) => (
        <li key={index} className="exercise-list-item list-group-item fs-5 p-0">
          {/* {isLimitReached ? (
            <Link
              to="/premium"
              className={`${styles.listItemLink} d-flex align-items-center w-100 h-100 py-2 px-3`}
              style={{ color: '#a0a0a0' }}
            >
              <i className={`bi ${exercise.icon} me-3`}></i>
              <div className="fs-6">{t(`${exercise.name}`)}</div>
              <i className="bi bi-lock ms-1" title="Upgrade for access"></i>
            </Link>
          ) : ( */}
            <Link
              to={`/${section}/${exercise.exercise}`}
              className={`${styles.listItemLink} d-flex align-items-center w-100 h-100 py-2 px-3`}
            >
              <i className={`bi ${exercise.icon} me-3`}></i>
              <div className="fs-6">{t(`${exercise.name}`)}</div>
            </Link>
          {/* )} */}
        </li>
      ))}
    </ul>
  );

  if (loading || isCheckingLimit) {
    return (
      <div className="text-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
  }

  if (isLimitReached) {
    return <Navigate to="/premium" />;
  }

  return (
    <div className="container mt-4 col-xxl-10">
      <h1 className="text-center mb-4">{t('exercises')}</h1>
      <div className="row mt-4">
        <div className="col-lg-3 col-md-6 mb-4 mt-2">
          <h2 className="text-center mb-4 fs-4">
            <i className="bi bi-book me-2"></i> {t('reading')}
          </h2>
          {renderExercises(readingExercises, 'reading')}
        </div>
        <div className="col-lg-3 col-md-6 mb-4 mt-2">
          <h2 className="text-center mb-4 fs-4">
            <i className="bi bi-headphones me-2"></i> {t('listening')}
          </h2>
          {renderExercises(listeningExercises, 'listening')}
        </div>
        <div className="col-lg-3 col-md-6 mb-4 mt-2">
          <h2 className="text-center mb-4 fs-4">
            <i className="bi bi-mic me-2"></i> {t('speaking')}
          </h2>
          {renderExercises(speakingExercises, 'speaking')}
        </div>
        <div className="col-lg-3 col-md-6 mb-4 mt-2">
          <h2 className="text-center mb-4 fs-4">
            <i className="bi bi-pencil me-2"></i> {t('writing')}
          </h2>
          {renderExercises(writingExercises, 'writing')}
        </div>
      </div>
    </div>
  );
};

export default ExercisesPage;
