import { useState, useEffect } from 'react';
import { checkExerciseLimits } from '../services/exerciseLimitService';

export const useExerciseLimits = () => {
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [isCheckingLimit, setIsCheckingLimit] = useState(true);

  const checkLimit = async () => {
    setIsCheckingLimit(true);
    const limitReached = await checkExerciseLimits();
    setIsLimitReached(limitReached);
    setIsCheckingLimit(false);
  };

  useEffect(() => {
    checkLimit();
  }, []);

  return { isLimitReached, isCheckingLimit, checkLimit };
}; 