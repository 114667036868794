import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import Badge from './Badge';
import styles from '../styles/ProgressBar.module.css';
import BadgePopup from './BadgePopup';
import BadgeCollection from './BadgeCollection';

const ProgressBar = ({ triggerRefresh }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [stats, setStats] = useState({
    dailyGoal: 3,
    completedToday: 0,
    streakDays: 0,
    badges: [],
    newBadges: [],
    readingScore: 0,
    readingTrend: 0,
    listeningScore: 0,
    listeningTrend: 0,
    writingScore: 0,
    writingTrend: 0,
    speakingScore: 0,
    speakingTrend: 0
  });
  const [showBadges, setShowBadges] = useState(false);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch('/api/stats/daily', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setStats(data);
        }
      } catch (error) {
        console.error('Failed to fetch daily stats:', error);
      }
    };

    fetchStats();
  }, [user, triggerRefresh]);

  const progress = Math.min((stats.completedToday / stats.dailyGoal) * 100, 100);
  
  const skills = [
    { 
      name: 'reading', 
      color: '#4CAF50', 
      progress: stats.readingScore,
      trend: stats.readingTrend 
    },
    { 
      name: 'listening', 
      color: '#2196F3', 
      progress: stats.listeningScore,
      trend: stats.listeningTrend 
    },
    { 
      name: 'writing', 
      color: '#9C27B0', 
      progress: stats.writingScore,
      trend: stats.writingTrend 
    },
    { 
      name: 'speaking', 
      color: '#FF9800', 
      progress: stats.speakingScore,
      trend: stats.speakingTrend 
    }
  ].filter(skill => user.exerciseTypes?.includes(skill.name));

  const MotivationalMessage = () => (
    <div className={styles.message}>
      {progress < 100 ? (
        <small className="text-muted">
          {t('progress_more_to_goal', { count: stats.dailyGoal - stats.completedToday })}
        </small>
      ) : (
        <small className="text-success">
          <i className="bi-check-circle me-1"></i>
          {t('progress_goal_achieved')}
        </small>
      )}
    </div>
  );

  return (
    <>
      <div className={`${styles.progressContainer}`}>
        <div className={styles.statsRow}>
          <div className="daily-target">
            <span className="h5 mb-0">{stats.completedToday}/{stats.dailyGoal}</span>
            <span className="text-muted ms-2">{t('progress_exercises_today')}</span>
          </div>
          
          <div className={styles.messageDesktop}>
            <MotivationalMessage />
          </div>

          <div className="d-flex align-items-center">
            <i className="bi-fire text-warning me-2"></i>
            <span>{t('progress_day_streak', { count: stats.streakDays })}</span>
          </div>
          
          <div className={styles.badgeRow} onClick={() => setShowBadges(true)}>
            {stats.badges.slice(0, 3).reverse().map(badge => (
              <Badge 
                key={badge._id}
                name={badge.name}
                earnedAt={badge.earnedAt}
              />
            ))}
            {stats.badges.length > 3 && (
              <div className={styles.badgeMore}>
                +{stats.badges.length - 3}
              </div>
            )}
          </div>
        </div>

        <div className={styles.progressBar}>
          <div 
            className={`${styles.progressFill} ${progress === 100 ? styles.progressFillComplete : ''}`}
            role="progressbar" 
            style={{ width: `${progress}%` }}
            aria-valuenow={progress} 
            aria-valuemin="0" 
            aria-valuemax="100"
          />
        </div>

        <div className={styles.messageMobile}>
          <MotivationalMessage />
        </div>

        <div className={styles.skillBars}>
          {skills.map(skill => (
            <div key={skill.name} className={styles.skillBar}>
              <div className="d-flex justify-content-between align-items-center mb-1">
                <span className="text-capitalize small">{t(skill.name)}</span>
                <div>
                  <span className="small">{skill.progress}%</span>
                  {skill.trend !== 0 && (
                    <span className={`ms-2 small ${skill.trend > 0 ? 'text-success' : 'text-danger'}`}>
                      <i className={`bi-arrow-${skill.trend > 0 ? 'up' : 'down'}`}></i>
                      {Math.abs(skill.trend)}%
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.skillProgress}>
                <div 
                  className={styles.skillProgressFill}
                  style={{ 
                    width: `${skill.progress}%`,
                    backgroundColor: skill.color 
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {showBadges && (
        <BadgeCollection 
          badges={stats.badges} 
          onClose={() => setShowBadges(false)} 
        />
      )}

      {stats.newBadges && stats.newBadges.length > 0 && (
        <BadgePopup 
          badges={stats.newBadges}
          onComplete={() => setStats(prev => ({ ...prev, newBadges: [] }))}
        />
      )}
    </>
  );
};

export default ProgressBar;