import React, { useContext, useState, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useExerciseLimits } from '../hooks/useExerciseLimits';
import Exercise from '../components/Exercise';

const PracticePage = () => {
  const { user, loading } = useContext(AuthContext);
  const [currentExercise, setCurrentExercise] = useState(null);
  const initialLoadDone = useRef(false);
  const { isLimitReached, isCheckingLimit } = useExerciseLimits();

  const fetchNextExercise = async () => {
    try {
      const response = await fetch('/api/exercises/next', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        credentials: 'include',
      });

      if (!response.ok) throw new Error('Failed to fetch next exercise');
      
      const data = await response.json();
      setCurrentExercise(data);
    } catch (error) {
      console.error('Error fetching next exercise:', error);
      setCurrentExercise(null);
    }
  };

  useEffect(() => {
    if (!loading && user && !initialLoadDone.current) {
      fetchNextExercise();
      initialLoadDone.current = true;
    }
  }, [loading, user]);

  if (loading || isCheckingLimit) {
    return (
      <div className="text-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
  }

  if (isLimitReached) {
    return <Navigate to="/premium" />;
  }

  if (!currentExercise) {
    return (
      <div className="text-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
  }

  return (
    <Exercise
      key={`${currentExercise.section}-${currentExercise.exercise}`}
      section={currentExercise.section}
      exercise={currentExercise.exercise}
      onNext={fetchNextExercise}
      disableBack={true}
    />
  );
};

export default PracticePage;