import React from 'react';

const Footer = () => {
  return (
    <div className="container">
      <footer className="d-flex flex-column flex-md-row flex-wrap justify-content-between align-items-center py-3 my-3 border-top">
        <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start align-items-center mb-md-0">
          <span className="text-body-secondary">© 2025 Fluentious</span>
        </div>

        {/* Uncomment if needed */}
        {/* <ul className="nav col-12 col-md-4 justify-content-center justify-content-md-end list-unstyled d-flex mb-2 mb-md-0">
          <li className="ms-3">
            <a className="text-body-secondary" href="#">
              <i className="bi bi-twitter" style={{ fontSize: '24px' }}></i>
            </a>
          </li>
          <li className="ms-3">
            <a className="text-body-secondary" href="#">
              <i className="bi bi-instagram" style={{ fontSize: '24px' }}></i>
            </a>
          </li>
          <li className="ms-3">
            <a className="text-body-secondary" href="#">
              <i className="bi bi-facebook" style={{ fontSize: '24px' }}></i>
            </a>
          </li>
        </ul> */}
      </footer>
    </div>
  );
};

export default Footer;
