import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/Feature.module.css';

const Feature = ({ heading, description, image, buttonText, textOnLeft, isLast }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/signup');
  };

  return (
    <div className={`${styles.featureWrapper} ${isLast ? styles.lastFeature : ''}`}>
      <div className={`row align-items-stretch g-5 py-3 mx-0 ${textOnLeft ? '' : 'flex-lg-row-reverse'}`}>
        <div className="feature-thumbnail col-12 col-md-8 col-lg-6 mb-lg-0 border rounded d-flex align-items-center justify-content-center my-0 mb-4 p-0">
          <img 
            src={image} 
            className="d-block mx-auto img-fluid p-3 p-lg-5" 
            alt={heading} 
            style={{ maxWidth: '100%', height: '100%', objectFit: 'contain' }} 
            loading="lazy" 
          />
        </div>
        <div className="col-12 col-lg-6 text-center text-lg-start my-0 d-flex flex-column justify-content-center">
          <h1 className="display-5 text-body-emphasis lh-1 mb-3">
            {heading}
          </h1>
          <p className="lead">
            {description}
          </p>
          <div className="d-grid gap-2 d-md-flex justify-content-md-start justify-content-center">
            <button 
              type="button" 
              className="btn btn-primary btn-lg px-4" 
              onClick={handleButtonClick}
              style={{ width: '100%', maxWidth: '300px' }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
