import React from 'react';

const Prompt = ({ prompt }) => {
  return (
    prompt ? (
      <div className="alert alert-warning">
        {prompt}
      </div>
    ) : null
  );
};

export default Prompt;
