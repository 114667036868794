import React from 'react';
import { useTranslation } from 'react-i18next';

const TimeCount = ({ currentTime, targetTimeRange }) => {
  const { t } = useTranslation();
  
  // Parse the target time range (expected format: "0.5-1" or "1-2" in minutes)
  const [min, max] = targetTimeRange.split('-').map(Number);
  
  // Convert to seconds for comparison
  const minSeconds = min * 60;
  const maxSeconds = max * 60;
  const currentSeconds = currentTime;
  
  // Calculate progress percentage (capped at 100%)
  const progress = Math.min((currentSeconds / maxSeconds) * 100, 100);
  
  // Determine the status and styling
  let statusColor = '';
  let progressBarColor = '';
  let animation = '';
  
  if (currentSeconds < minSeconds) {
    statusColor = '#dc3545';
    progressBarColor = '#ffd7d9';
  } else if (currentSeconds > maxSeconds) {
    statusColor = '#dc3545';
    progressBarColor = '#dc3545';
    animation = 'pulse 2s infinite';
  } else {
    statusColor = '#198754';
    progressBarColor = '#198754';
  }

  // Format time display (MM:SS)
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="mt-2 mb-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div style={{ fontSize: '0.9rem', color: '#6c757d' }}>
          {t('target_time_range', { min, max })}
        </div>
        <div 
          style={{ 
            fontSize: '0.9rem',
            fontWeight: '500',
            color: statusColor,
            transition: 'color 0.3s ease'
          }}
        >
          {formatTime(currentSeconds)}
        </div>
      </div>

      <div 
        style={{ 
          height: '4px',
          backgroundColor: '#e9ecef',
          borderRadius: '2px',
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        <div 
          style={{
            position: 'absolute',
            left: `${(minSeconds / maxSeconds) * 100}%`,
            right: `${100 - (maxSeconds / maxSeconds) * 100}%`,
            height: '100%',
            borderLeft: '2px solid #adb5bd',
            borderRight: '2px solid #adb5bd',
            zIndex: 2
          }}
        />
        <div 
          style={{
            width: `${progress}%`,
            height: '100%',
            backgroundColor: progressBarColor,
            transition: 'all 0.3s ease',
            animation: animation,
            position: 'relative',
            zIndex: 1
          }}
        />
      </div>

      <style>
        {`
          @keyframes pulse {
            0% { opacity: 1; }
            50% { opacity: 0.7; }
            100% { opacity: 1; }
          }
        `}
      </style>
    </div>
  );
};

export default TimeCount; 