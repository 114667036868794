export const checkExerciseLimits = async () => {
  try {
    const response = await fetch('/api/exercises/limit-reached', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    
    if (!response.ok) {
      throw new Error('Failed to check exercise limits');
    }

    const { limitReached } = await response.json();
    return limitReached;
  } catch (error) {
    console.error('Error checking exercise limits:', error);
    return true; // Fail safe - assume limit is reached if there's an error
  }
}; 