import React, { useState, useEffect, useRef, useContext } from "react";
import { AuthContext } from '../context/AuthContext';
import useStreamExercise from "../hooks/useStreamExercise";
import useStreamFeedback from "../hooks/useStreamFeedback";
import WritingArea from "./WritingArea";
import ErrorMessage from "./ErrorMessage";
import FeedbackWriting from "./FeedbackWriting";
import Instructions from "./Instructions";
import Prompt from "./Prompt";
import Table from "./Table";
import SubmitButton from "./SubmitButton";
import Graph from "./Graph";
import Chart from "./Chart";
import ExerciseTitle from "./ExerciseTitle";
import Passage from "./Passage";
import ProgressBar from "./ProgressBar";
import ExerciseNavigation from "./ExerciseNavigation";
import { useNavigate } from 'react-router-dom';
import LikeExercise from './LikeExercise';
import CorrectedResponse from './CorrectedResponse';
import WordCount from './WordCount';

const WritingExercise = ({ api, onBack: customOnBack, onNext: customOnNext, disableBack }) => {
  const { user, refreshUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { exercise, exerciseId, error: exerciseError, refetchExercise } = useStreamExercise(api);
  const { feedback, setFeedback, error: feedbackError, submitExercise, isComplete: isFeedbackComplete } = useStreamFeedback(api);
  const [essayText, setEssayText] = useState('');
  const feedbackWasNull = useRef(true);
  const [likeReset, setLikeReset] = useState(0);

  useEffect(() => {
    if (feedback !== null && feedbackWasNull.current) refreshUser();
    feedbackWasNull.current = feedback === null;
  }, [feedback, refreshUser]);

  const handleEssayChange = (text) => { setEssayText(text); };

  useEffect(() => {
    if (feedback?.correction) {
      setEssayText(feedback.correction);
    }
  }, [feedback?.correction]);

  const cleanSubmission = (text) => {
    return text
      .replace(/<del>[\s\S]*?<\/del>/g, '')
      .replace(/<ins>([\s\S]*?)<\/ins>/g, '$1')
      .replace(/<div>/g, '\n')
      .replace(/<\/div>/g, '');
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("exerciseId", exerciseId);
    formData.append("response", cleanSubmission(essayText));
    submitExercise(formData);
  };

  const handleRefresh = () => { 
    setEssayText(''); 
    setFeedback(null); 
    refetchExercise();
    setLikeReset(prev => prev + 1);
  };

  const handleBack = () => customOnBack?.() || navigate('/exercises');
  const handleNext = () => customOnNext?.() || handleRefresh();

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <ExerciseTitle api={api} />
      <div style={{ background: '#fff', borderRadius: '12px', boxShadow: '0 0 10px rgba(0,0,0,0.05)' }}>
        <ProgressBar triggerRefresh={isFeedbackComplete} />
        <div className="p-4">
          {(exerciseError || feedbackError) && <ErrorMessage error={exerciseError || feedbackError} />}
          {!(exerciseError || feedbackError) && <>
            <Instructions instructions={exercise?.instructions} />
            {exercise?.prompt && <Prompt prompt={exercise.prompt} />}
            {exercise?.passage && <Passage
              title={exercise.passage.title} 
              passage={exercise.passage.content} />}
            {exercise?.table && <Table table={exercise.table} />}
            {exercise?.graph && <Graph graph={exercise.graph} />}
            {exercise?.chart && <Chart chart={exercise.chart} />}
            {!feedback && <WritingArea value={essayText} onChange={handleEssayChange} />}
            {!feedback && exercise?.wordCount && <WordCount 
              text={essayText} 
              targetWordCount={exercise.wordCount} 
            />}
            {!feedback && <SubmitButton onClick={handleSubmit} isSubmitting={false} />}
            {feedback && <CorrectedResponse text={feedback.correction} />}
            {feedback && <FeedbackWriting feedback={feedback} />}
          </>}
        </div>
      </div>
      <LikeExercise exerciseId={exerciseId} reset={likeReset} />
      <ExerciseNavigation
        onBack={handleBack}
        onNext={handleNext}
        disableBack={disableBack}
        triggerRefresh={exercise !== null} />
    </div>
  );
};

export default WritingExercise;
