import { useState, useEffect, useCallback } from 'react';
import { jsonrepair } from 'jsonrepair';

const useStreamFeedback = (api) => {
  const [rawFeedback, setRawFeedback] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isComplete, setIsComplete] = useState(false);

  const submitExercise = useCallback(async (formData) => {
    setRawFeedback('');
    setFeedback(null);
    setError(null);
    setIsLoading(true);
    setIsComplete(false);

    let reader;
    try {
      const token = localStorage.getItem('token');
      
      const response = await fetch(api, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Failed to submit exercise: ${response.status}`);
      }

      reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n').filter(line => line.trim());

        for (const line of lines) {
          try {
            const jsonLine = line.replace(/^data: /, '');
            const data = JSON.parse(jsonLine);
            
            if (data.delta) {
              setRawFeedback(prev => {
                const newRawFeedback = prev + data.delta;
                try {
                  const repairedJson = jsonrepair(newRawFeedback);
                  const partialFeedback = JSON.parse(repairedJson);
                  setFeedback(partialFeedback);
                } catch (e) {
                  // JSON still too incomplete to parse
                }
                return newRawFeedback;
              });
            }
            
            if (data.status === 'complete') {
              setIsLoading(false);
              setIsComplete(true);
            } else if (data.status === 'error') {
              throw new Error(data.message || 'Unknown error occurred');
            }
          } catch (e) {
            // Skip invalid JSON lines
            // console.debug('Skipping invalid JSON line:', e);
          }
        }
      }

    } catch (error) {
      // console.error('Stream error:', error);
      setError(error.message || 'Failed to get feedback');
      setIsLoading(false);
    } finally {
      // Ensure reader is properly closed if it exists
      if (reader) {
        reader.cancel();
      }
    }
  }, [api]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      setRawFeedback('');
      setFeedback(null);
      setError(null);
      setIsLoading(false);
      setIsComplete(false);
    };
  }, []);

  return { 
    feedback, 
    isLoading, 
    error, 
    submitExercise,
    setFeedback,
    isComplete
  };
};

export default useStreamFeedback;