import React from 'react';

const Images = ({ urls }) => {
  if (!urls || urls.length !== 2) return null;

  return (
    <div className="alert alert-light">
      <div className="row g-4">
        <div className="col-md-6">
          <div className="d-flex justify-content-center">
            <img 
              src={urls[0]} 
              alt="First" 
              className="img-fluid" 
              width="400px" 
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex justify-content-center">
            <img 
              src={urls[1]} 
              alt="Second" 
              className="img-fluid" 
              width="400px" 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Images; 