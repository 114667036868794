import React from 'react';
import Badge from './Badge';
import { useTranslation } from 'react-i18next';
import styles from '../styles/BadgeCollection.module.css';

const BadgeCollection = ({ badges, onClose }) => {
  const { t } = useTranslation();

  // Helper function to sort badges by timestamp (newest first)
  const sortByDate = (badges) => {
    return [...badges].sort((a, b) => b.earnedAt - a.earnedAt);
  };

  const categorizedBadges = {
    streak: sortByDate(badges.filter(b => b.name.startsWith('streak_'))),
    perfect: sortByDate(badges.filter(b => b.name.startsWith('perfect_'))),
    exercises: sortByDate(badges.filter(b => b.name.startsWith('exercises_'))),
    reading: sortByDate(badges.filter(b => b.name.startsWith('reading_'))),
    listening: sortByDate(badges.filter(b => b.name.startsWith('listening_'))),
    speaking: sortByDate(badges.filter(b => b.name.startsWith('speaking_'))),
    writing: sortByDate(badges.filter(b => b.name.startsWith('writing_'))),
    special: sortByDate(badges.filter(b => ['early_bird', 'night_owl', 'weekend_warrior'].includes(b.name)))
  };

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div className={styles.modal} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <h3>{t('your_badges')}</h3>
          <button className={styles.closeButton} onClick={onClose}>
            <i className="bi-x-lg"></i>
          </button>
        </div>

        <div className={styles.content}>
          {Object.entries(categorizedBadges).map(([category, categoryBadges]) => (
            categoryBadges.length > 0 && (
              <div key={category} className={styles.category}>
                <h4>{t(`badge_category_${category}`)}</h4>
                <div className={styles.badgeGrid}>
                  {categoryBadges.map(badge => (
                    <div key={badge._id} className={styles.badgeItem}>
                      <Badge name={badge.name} />
                      <div className={styles.badgeInfo}>
                        <div className={styles.badgeName}>{t(`badge_${badge.name}`)}</div>
                        <div className={styles.earnedDate}>
                          {t('earned_on', { 
                            date: new Date(badge.earnedAt).toLocaleDateString() 
                          })}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

export default BadgeCollection; 