import { useState, useRef, useEffect, useContext } from 'react';
import useStreamExercise from "../hooks/useStreamExercise";
import ErrorMessage from "./ErrorMessage";
import MultipleChoice from "./MultipleChoice";
import SentenceCompletion from "./SentenceCompletion";
import Transcript from "./Transcript";
import AudioPlayer from "./AudioPlayer";
import Instructions from "./Instructions";
import ExerciseTitle from "./ExerciseTitle";
import TableCompletion from "./TableCompletion";
import NoteCompletion from "./NoteCompletion";
import SummaryCompletion from "./SummaryCompletion";
import ShortAnswerQuestions from "./ShortAnswerQuestions";
import ProgressBar from "./ProgressBar";
import useSubmitScore from "../hooks/useSubmitScore";
import ExerciseNavigation from "./ExerciseNavigation";
import Score from "./Score";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LikeExercise from './LikeExercise';

const ListeningExercise = ({ api, onBack: customOnBack, onNext: customOnNext, disableBack }) => {
  const { user, refreshUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { exercise, exerciseId, audioChunks, error, refetchExercise } = useStreamExercise(api);
  const { score, setScore, submitScore } = useSubmitScore();
  const [highlight, setHighlight] = useState("");
  const exerciseWasNull = useRef(true);
  const [likeReset, setLikeReset] = useState(0);

  useEffect(() => {
    if (exercise !== null && exerciseWasNull.current) refreshUser();
    exerciseWasNull.current = exercise === null;
  }, [exercise, refreshUser]);

  const handleCompletion = async (response, calculatedScore) => { await submitScore(exerciseId, response, calculatedScore); };
  const handleRefresh = () => { 
    setHighlight(""); 
    setScore(null); 
    refetchExercise();
    setLikeReset(prev => prev + 1);
  };

  const handleBack = () => customOnBack?.() || navigate('/exercises');
  const handleNext = () => customOnNext?.() || handleRefresh();

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <ExerciseTitle api={api} />
      <div style={{ background: '#fff', borderRadius: '12px', boxShadow: '0 0 10px rgba(0,0,0,0.05)' }}>
        <ProgressBar triggerRefresh={score} />
        <div className="p-4">
          {error && <ErrorMessage error={error} />}
          {!error && <>
            <Instructions instructions={exercise?.instructions} />
            <AudioPlayer 
              audioChunks={audioChunks} 
              onHighlight={setHighlight}
            />
            <Transcript 
              conversation={exercise?.conversation} 
              highlight={highlight} 
              // currentAudioChunk={audioChunks[audioChunks.length - 1]}
            />
            {exercise?.multiple_choice && <MultipleChoice
              questions={exercise.multiple_choice}
              onHighlightChange={setHighlight}
              onComplete={handleCompletion}
              shuffled={true} />}
            {exercise?.true_false_not_given && <MultipleChoice
              questions={exercise.true_false_not_given}
              onHighlightChange={setHighlight}
              onComplete={handleCompletion}
              shuffled={false} />}
            {exercise?.matching_speakers && <MultipleChoice
              questions={exercise.matching_speakers}
              onHighlightChange={setHighlight}
              onComplete={handleCompletion}
              shuffled={false} />}
            {exercise?.sentence_completion && <SentenceCompletion
              blanks={exercise.sentence_completion}
              onHighlightChange={setHighlight}
              onComplete={handleCompletion} />}
            {exercise?.table_completion && <TableCompletion
              table={exercise.table_completion.table}
              onHighlightChange={setHighlight}
              answers={exercise.table_completion.answers}
              onComplete={handleCompletion} />}
            {exercise?.note_completion && <NoteCompletion
              heading={exercise.note_completion.heading}
              notes={exercise.note_completion.notes}
              onComplete={handleCompletion}
              onHighlightChange={setHighlight} />}
            {exercise?.summary_completion && <SummaryCompletion
              summary={exercise.summary_completion}
              onComplete={handleCompletion}
              onHighlightChange={setHighlight} />}
            {exercise?.short_answer_questions && <ShortAnswerQuestions
              short_answer_questions={exercise.short_answer_questions}
              onComplete={handleCompletion}
              onHighlightChange={setHighlight} />}
            {score !== null && <Score score={score} />}
          </>}
        </div>
      </div>
      <LikeExercise exerciseId={exerciseId} reset={likeReset} />
      <ExerciseNavigation
        onBack={handleBack}
        onNext={handleNext}
        disableBack={disableBack}
        triggerRefresh={exercise !== null} />
    </div>
  );
};

export default ListeningExercise;
