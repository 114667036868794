import React, { useState } from "react";
import Input from "./Input";
import Explanations from "./Explanations";
import checkAnswer from '../utils/checkAnswer';

// Helper function to check if all blanks are filled
const areAllBlanksFilled = (userAnswers, gapCount) => {
  if (!gapCount) return false;
  return Array.from({ length: gapCount }).every((_, index) => 
    userAnswers[index]?.submitted
  );
};

// Helper function to calculate score
const calculateScore = (userAnswers, gapCount) => {
  if (!gapCount) return 0;
  return Object.values(userAnswers).filter(answer => answer.isCorrect).length / gapCount;
};

const SummaryCompletion = ({ summary, onComplete, onHighlightChange }) => {
  const [userAnswers, setUserAnswers] = useState({});
  const [explanations, setExplanations] = useState([]);

  const handleInputChange = (index, value) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: {
        userAnswer: value,
        isCorrect: false,
        submitted: false,
      },
    }));
  };

  const handleSubmit = (index, gapData) => {
    const userAnswer = userAnswers[index]?.userAnswer;
    if (!userAnswer || !gapData) return;

    const isCorrect = checkAnswer(userAnswer, gapData.answer);

    setUserAnswers((prevAnswers) => {
      const updatedAnswers = {
        ...prevAnswers,
        [index]: { 
          userAnswer, 
          isCorrect, 
          submitted: true 
        },
      };

      onHighlightChange(gapData.reference);

      setExplanations((prevExplanations) => [
        ...prevExplanations,
        { number: index + 1, text: gapData.explanation }
      ]);

      // Count total gaps for score calculation
      const gapCount = summary.filter(item => item.gap).length;
      
      if (areAllBlanksFilled(updatedAnswers, gapCount)) {
        const score = calculateScore(updatedAnswers, gapCount);
        // Create answers array in correct order
        const answersArray = Array.from({ length: gapCount }, (_, i) => 
          updatedAnswers[i]?.userAnswer || ""
        );
        onComplete(answersArray, score);
      }

      return updatedAnswers;
    });
  };

  const renderSummary = () => {
    if (!Array.isArray(summary)) return null;

    let gapIndex = 0;

    return (
      <div className="mb-5">
        <p style={{ textAlign: "justify" }}>
          {summary.map((item, index) => {
            if (item.text) {
              return <span key={index}>{item.text}</span>;
            }
            
            if (item.gap) {
              const currentGapIndex = gapIndex++;
              const userAnswer = userAnswers[currentGapIndex];

              return (
                <span key={index} className="mx-1" style={{ display: "inline-flex", alignItems: "center" }}>
                  <strong>({currentGapIndex + 1})</strong>
                  <Input
                    userAnswer={userAnswer?.userAnswer}
                    onInputChange={(value) => handleInputChange(currentGapIndex, value)}
                    onSubmit={() => handleSubmit(currentGapIndex, item.gap)}
                    isSubmitted={userAnswer?.submitted}
                    isCorrect={userAnswer?.isCorrect}
                    correctAnswer={item.gap.answer}
                  />
                </span>
              );
            }

            return null;
          })}
        </p>

        {explanations.length > 0 && <Explanations explanations={explanations} />}
      </div>
    );
  };

  if (!summary) {
    return null;
  }

  return (
    <div>
      {renderSummary()}
    </div>
  );
};

export default SummaryCompletion;