import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import LoadingSpinner from './LoadingSpinner';

const AudioRecorder = ({ onStopRecording, isSubmitting, onTimeUpdate }) => {
  const { t } = useTranslation(); // Initialize the translation hook
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const mediaStreamRef = useRef(null);
  const [elapsedTime, setElapsedTime] = useState(0);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;

      let mimeType = '';

      if (MediaRecorder.isTypeSupported('audio/webm')) {
        mimeType = 'audio/webm';
      } else if (MediaRecorder.isTypeSupported('audio/ogg')) {
        mimeType = 'audio/ogg';
      } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
        mimeType = 'audio/mp4';
      } else {
        alert(t('unsupported_format_alert')); // Translated alert for unsupported formats
        return;
      }

      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(audioChunksRef.current, { type: mimeType });
        audioChunksRef.current = [];
        onStopRecording(blob); // Call the parent component's function with the audio blob
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
      alert(t('microphone_access_error')); // Translated alert for microphone access error
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    setIsRecording(false);
  };

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setElapsedTime(prev => {
          const newTime = prev + 1;
          onTimeUpdate?.(newTime);
          return newTime;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRecording, onTimeUpdate]);

  return (
    <div className="d-flex">
      <button
        onClick={() => (isRecording ? stopRecording() : startRecording())}
        className={`btn ${isRecording || isSubmitting ? 'btn-secondary' : 'btn-success'}`}
        disabled={isSubmitting}
      >
        {isRecording || isSubmitting ? t('stop_recording') : t('start_recording')} {/* Translated button */}
      </button>
      {isSubmitting && <LoadingSpinner inline={true} />}
    </div>
  );
};

export default AudioRecorder;
