import React from 'react';
import styles from '../styles/Badge.module.css';

const BADGES = {
  // Streak badges
  'streak_3': { icon: 'bi-fire', style: styles.badgeBronze },      // Quick win (1st milestone)
  'streak_7': { icon: 'bi-fire', style: styles.badgeSilver },      // Solid start
  'streak_14': { icon: 'bi-fire', style: styles.badgeGold },     // Trial master
  'streak_30': { icon: 'bi-fire', style: styles.badgePlatinum },  // Premium milestone
  
  // Perfect score badges
  'perfect_1': { icon: 'bi-star-fill', style: styles.badgeBronze },    // First perfect
  'perfect_5': { icon: 'bi-star-fill', style: styles.badgeSilver },    // Consistent quality
  'perfect_15': { icon: 'bi-star-fill', style: styles.badgeGold },     // Excellence
  'perfect_30': { icon: 'bi-star-fill', style: styles.badgePlatinum }, // Mastery
  
  // Exercise count badges
  'exercises_3': { icon: 'bi-trophy-fill', style: styles.badgeBronze },    // First day complete
  'exercises_10': { icon: 'bi-trophy-fill', style: styles.badgeSilver },   // Early commitment
  'exercises_25': { icon: 'bi-trophy-fill', style: styles.badgeGold },     // Trial champion
  'exercises_50': { icon: 'bi-trophy-fill', style: styles.badgePlatinum },  // Premium achiever
  
  // Skill-specific badges
  'reading_5': { icon: 'bi-book', style: styles.badgeBronze },
  'reading_15': { icon: 'bi-book', style: styles.badgeSilver },
  'reading_30': { icon: 'bi-book', style: styles.badgeGold },
  
  'listening_5': { icon: 'bi-headphones', style: styles.badgeBronze },
  'listening_15': { icon: 'bi-headphones', style: styles.badgeSilver },
  'listening_30': { icon: 'bi-headphones', style: styles.badgeGold },
  
  'speaking_5': { icon: 'bi-mic-fill', style: styles.badgeBronze },
  'speaking_15': { icon: 'bi-mic-fill', style: styles.badgeSilver },
  'speaking_30': { icon: 'bi-mic-fill', style: styles.badgeGold },
  
  'writing_5': { icon: 'bi-pencil-fill', style: styles.badgeBronze },
  'writing_15': { icon: 'bi-pencil-fill', style: styles.badgeSilver },
  'writing_30': { icon: 'bi-pencil-fill', style: styles.badgeGold },
  
  // Time-based badges
  'early_bird': { icon: 'bi-sunrise', style: styles.badgeGold },
  'night_owl': { icon: 'bi-moon-stars', style: styles.badgeGold },
  'weekend_warrior': { icon: 'bi-calendar-check', style: styles.badgeGold }
};

const Badge = ({ name }) => {
  const badge = BADGES[name] || { icon: 'bi-award', style: styles.badgeDefault };

  return (
    <div className={styles.badge}>
      <div className={`${styles.badgeIcon} ${badge.style}`}>
        <i className={`bi ${badge.icon}`}></i>
      </div>
    </div>
  );
};

export default Badge; 