import React, { useState, useEffect } from 'react';
import BlankItem from './BlankItem';
import checkAnswer from '../utils/checkAnswer';

const SentenceCompletion = ({ blanks, onHighlightChange, onComplete }) => {
  const [userAnswers, setUserAnswers] = useState({});
  const [results, setResults] = useState({});
  const [hoveredQuestionIndex, setHoveredQuestionIndex] = useState(null);
  const [lastAnsweredIndex, setLastAnsweredIndex] = useState(null);
  const [isExerciseCompleted, setIsExerciseCompleted] = useState(false);

  useEffect(() => {
    setUserAnswers({});
    setResults({});
    setHoveredQuestionIndex(null);
    setLastAnsweredIndex(null);
    setIsExerciseCompleted(false);
    onHighlightChange("");
  }, [onHighlightChange]);

  useEffect(() => {
    if (hoveredQuestionIndex !== null && results[hoveredQuestionIndex]) {
      onHighlightChange(results[hoveredQuestionIndex].reference);
    } else if (lastAnsweredIndex !== null && results[lastAnsweredIndex]) {
      onHighlightChange(results[lastAnsweredIndex].reference);
    } else {
      onHighlightChange("");
    }
  }, [hoveredQuestionIndex, lastAnsweredIndex, results, onHighlightChange]);

  const handleInputChange = (index, value) => {
    setUserAnswers((prevAnswers) => ({ ...prevAnswers, [index]: value }));
  };

  const handleSubmit = (index) => {
    const question = blanks[index];
    const userAnswer = userAnswers[index] || "";
    const isCorrect = checkAnswer(userAnswer, question.answer);

    const newResult = {
      userAnswer,
      correctAnswer: question.answer,
      isCorrect,
      reference: question.reference,
      explanation: question.explanation,
    };

    setResults((prevResults) => {
      const updatedResults = { ...prevResults, [index]: newResult };
      if (Object.keys(updatedResults).length === blanks.length) {
        setIsExerciseCompleted(true);
        const score = calculateScore(updatedResults);
        const answersArray = Array.from({ length: blanks.length }, (_, i) => 
          userAnswers[i] || ""
        );
        onComplete(answersArray, score);
      }
      return updatedResults;
    });

    setLastAnsweredIndex(index);
  };

  const handleMouseEnter = (index) => { setHoveredQuestionIndex(index); };
  const handleMouseLeave = () => { setHoveredQuestionIndex(null); };

  const calculateScore = (results) => {
    return Object.values(results).filter((result) => result.isCorrect).length / blanks.length;
  };

  return (
    <div className="question-item mb-5">
      {blanks.map((question, index) => (
        <BlankItem
          key={index}
          index={index}
          question={question}
          userAnswer={userAnswers[index]}
          result={results[index]}
          onInputChange={handleInputChange}
          onSubmit={handleSubmit}
          onHover={() => handleMouseEnter(index)}
          onLeave={handleMouseLeave}
        />
      ))}
    </div>
  );
};

export default SentenceCompletion;