import React, { useEffect, useState } from 'react';
import Badge from './Badge';
import { useTranslation } from 'react-i18next';
import styles from '../styles/BadgePopup.module.css';

const BadgePopup = ({ badges, onComplete }) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentBadge = badges[currentIndex];

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentIndex < badges.length - 1) {
        setCurrentIndex(prev => prev + 1);
      } else {
        onComplete();
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [currentIndex, badges.length, onComplete]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.badgeWrapper}>
          {[...Array(16)].map((_, i) => (
            <div key={i} className={styles.particle}></div>
          ))}
          <div className={styles.badgeScale}>
            <Badge name={currentBadge.name} />
          </div>
        </div>
        <h4>{t('badge_earned')}</h4>
        <h5>{t(`badge_${currentBadge.name}`)}</h5>
        {badges.length > 1 && (
          <div className={styles.badgeCounter}>
            {currentIndex + 1} / {badges.length}
          </div>
        )}
      </div>
    </div>
  );
};

export default BadgePopup; 