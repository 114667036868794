import axios from 'axios';

export const getUserProfile = async (token) => {
  const response = await axios.get('/api/user/profile', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const updateProficiencyLevel = async (proficiencyLevel, token) => {
  const response = await axios.put(
    '/api/user/profile', 
    { proficiencyLevel },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const updateKnownLanguage = async (knownLanguage, token) => {
  const response = await axios.put(
    '/api/user/profile',
    { knownLanguage },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const updateTargetLanguage = async (targetLanguage, token) => {
  const response = await axios.put(
    '/api/user/profile',
    { targetLanguage },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const updateTopic = async (topic, token) => {
  const response = await axios.put(
    '/api/user/profile',
    { topic },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const completeSetup = async (token) => {
  const response = await axios.post(
    '/api/user/complete-setup',
    {},
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  return response.data;
};

export const updateExerciseTypes = async (exerciseTypes, token) => {
  const response = await axios.put(
    '/api/user/profile',
    { exerciseTypes },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
